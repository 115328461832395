import React from 'react'
import './Team.scss'
import Heading from '../../components/Headings/Heading'
import MapTest from './Maptest'
import MapTest2 from './Maptest2'
import Heading4 from '../../components/Headings/Heading4'

const Location = () => {
  document.title = 'SMPeoples | Location';

  return (
      <div className='pt-16 min-h-screen'>
      <br/>
      <br/>
      <br/>
        <Heading heading="오시는길"></Heading>
        <div className="paragraph-body"></div>
          <p data-aos="fade-up" >
          <Heading4 details={"경기도 덕양구 고양시 청초로66 덕은리버워크 B동 1619, 1607호 "} text1={"본사 :"} text2={"  "} />
          </p>
          <br/>
          <br/>
          <center>
                <div>
                <MapTest/>
              </div> 
          </center>
           <br/>
           <br/>
           <br/>
          <p data-aos="fade-up" >
          <Heading4 details={"대전시 유성구 대학로75번길 12-16 202호 "} text1={"지사 :"} text2={"  "} />
          </p>
          <br/>
          <br/>
          <center>
              <div>
                <MapTest2/>
              </div> 
           </center>
            </div>

          )
}


export default Location