import { Link } from 'react-router-dom'
//import { FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'
const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="bg-[#0b0b0b] text-sm w-[100%] mt-[10rem]">

      <div className="mb-10 w-[90%] grid gap-2 md:gap-4 lg:gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 m-[auto] items-start justify-items-center">

        <div data-aos="fade-up" className='mt-10 w-[100%] md:w-[100%] lg:w-[100%] order-1 md:order-1 lg:order-1'>

          <Link to="/">
            <img src="/images/logo.svg" alt="logo" className='h-14 mb-2 w-auto' />
          </Link>

          <p className='max-w-[17rem] py-3 text-base'>Security Managing Peoples</p>

        </div>

        <div data-aos="fade-up" className='mt-10 w-[100%] md:w-[100%] lg:w-[100%] order-2 md:order-2 lg:order-2'>
          <div className='flex items-center justify-start'><h1 className='font-extrabold text-2xl text-blue-700 mr-3'>|</h1><h1 className='text-lg font-semibold'>사이트 맵</h1></div>
          <ul className='mt-4 flex flex-col gap-2 text-base'>
            <li className='hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="/about"><p className='text-gray-400 hover:text-sky-500'>Company</p></Link></li>
            <li className='hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="/Solutions"><p className='text-gray-400 hover:text-sky-500'>Solutions</p></Link></li>
            <li className=' hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="/team"><p className='text-gray-400 hover:text-sky-500'>News</p></Link></li>
            <li className='hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="/Location"><p className='text-gray-400 hover:text-sky-500'>Location</p></Link></li>
          </ul>
        </div>

        <div data-aos="fade-up" className='mt-10 w-[100%] md:w-[100%] lg:w-[100%] order-2 md:order-2 lg:order-2'>
          <div className='flex items-center justify-start'><h1 className='font-extrabold text-2xl text-blue-700 mr-3'>|</h1><h1 className='text-lg font-semibold'>벤더사</h1></div>
          <ul className='mt-4 flex flex-col gap-2 text-base'>
            <li className='hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="http://www.hanssak.co.kr/main/main.html"><p className='text-gray-400 hover:text-sky-500'>한싹</p></Link></li>
            <li className='hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="https://www.secuwiz.co.kr"><p className='text-gray-400 hover:text-sky-500'>시큐위즈</p></Link></li>
            <li className=' hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="https://www.piolink.com/kr/"><p className='text-gray-400 hover:text-sky-500'>파이오링크</p></Link></li>
            <li className=' hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="http://www.nilesoft.co.kr/"><p className='text-gray-400 hover:text-sky-500'>나일소프트</p></Link></li>
            <li className=' hover:text-blue-600'><Link style={{ textDecoration: "none" }} to="https://www.genians.co.kr/"><p className='text-gray-400 hover:text-sky-500'>지니언스</p></Link></li>
          </ul>
        </div>

        <div data-aos="fade-up" className='mt-10 w-[100%] md:w-[100%] lg:w-[100%] order-3 md:order-3 lg:order-3 text-base'>
          <div className='flex items-center justify-start'><h1 className='font-extrabold text-2xl text-blue-700 mr-3'>|</h1><h1 className='text-lg font-semibold'>문의</h1></div>
          <p className='max-w-[30rem] pt-2 text'> 본사: 경기 고양시 덕양구 청초로 66 B동 1619,1607호</p>
          <p className='max-w-[30rem] pt-2 text'> 지사: 대전시 유성구 대학로75번길 12-16 202호</p>
          <p className='pt-2'>영업문의: <a style={{ textDecoration: "none" }} href="mailto:sale@smpeoples.kr" className='text-blue-700 ml-2 font-semibold'><span className='hover:text-gray-400 text-sky-500'>sales@smpeoples.kr</span></a></p>
          <p className='pt-2'>기술문의: <a style={{ textDecoration: "none" }} href="mailto:tech@smpeoples.kr" className='text-blue-700 ml-2 font-semibold'><span className='hover:text-gray-400 text-sky-500'>tech@smpeoples.kr</span></a></p>
        </div>

      </div>

      <div className="w-[90%] m-[auto] bg-gray-800 h-[1px]"></div>
      <div className="m-[auto] pb-8 pt-5 text-gray-600 font-medium text-sm flex flex-col md:flex-row lg:flex-row justify-between items-center w-[90%]">
        <p>
          © {year} SMPeoples. All Rights Reserved.
        </p>
      </div>
    </div>
  )
}

export default Footer
