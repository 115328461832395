import './About.scss'
import Heading from '../../components/Headings/Heading'

const About = () => {

  document.title = 'SMPeoples | 소개';

  return (
    <div className='pt-16 min-h-screen'>
      <br/>
      <br/>
      <br/>
      <Heading heading="회사소개"></Heading>
      <div className="paragraph-body"> 
    <p data-aos="fade-up">
        <span>에스엠피플스㈜</span>에 방문해 주셔서 감사합니다.
    </p>
    <p data-aos="fade-up">
        우리는 정보보안의 최전선에서 고객의 안전과 신뢰를 지키기 위해 헌신하는 전문가들로 구성된 팀입니다.
    </p>
    <p data-aos="fade-up">
        오늘날의 디지털 환경은 끊임없이 변화하고 있으며, 우리는 이러한 변화에 적응하고
    </p>
    <p data-aos="fade-up">
        선제적으로 대응하기 위해 최신 기술과 전략을 채택하고 있습니다.
    </p>
    <p data-aos="fade-up">
        우리의 목표는 고객에게 최상의 보안 솔루션을 제공하여 소중한 정보를 안전하게 보호하는 것입니다.
    </p>
    <p data-aos="fade-up">
        고객의 안전은 우리의 최우선 과제입니다. 에스엠피플스와 함께 더 안전한 미래를 만들어 가십시오.
    </p>
    <p data-aos="fade-up">
        <span>감사합니다.</span> 
    </p>
</div>
    </div>
  )
}

export default About
